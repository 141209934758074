@import '../../../../styles/variables';

.container {
  width: 100%;
  .input {
    display: none;
  }
  &.is-input { 
    .label {
      @include transition;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 95, 191, 0.06);
      border: 1px dashed #005FBF;
      padding: 10px;
      &:hover {
        background: rgba(0, 95, 191, 0.1);
      }
      img {
        width: 30px;
        margin: 5px 10px;
        transform: translateY(2px);
      }
      p {
        @include bold;
        font-size: 14px;
        color: $primary;
      }
    }
  }
  .media {
    width: 100%;
    margin-bottom: 20px;
    img, video {
      border-radius: 3px;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
    .delete {
      @include btn;
      color: $error;
      float: right;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}