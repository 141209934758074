@import './variables.scss';

.information-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &.offer {
    width: calc(100% - 20px);
    &.border {
      border-top: 1px solid rgba(153, 191, 229, 0.36);
      padding-top : 20px;
    }
  }
  .input-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .col-1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .p-col-1 {
    margin-bottom: 20px;
    width: 100%;
    margin-right: 2%;
  }
  .contact-card {
    margin-bottom: 20px;
    width: calc(46% - 20px);
    margin-right: 20px;
    margin-bottom: 0px;
    box-shadow: $shadow;
    padding: 2%;
    @media screen and (max-width: $xs-screen) {
      width: calc(100%);
      margin: 10px 0px;
      padding: 3%;
    }
    .label {
      max-width: 90px;
      padding: 2px 12px;
      background-color: $primary;
      color: $white;
      text-align: center;
      border-radius: 15px;
    }
  }
  .p-col-3 {
    margin-bottom: 20px;
    width: 48%;
    margin-right: 2%;
    @media screen and (max-width: $xs-screen) {
      width: calc(100%);
      margin: 10px 0px;
    }
    &.offer {
      width: 31%;
      .number {
        font-size: 21px;
      }
    }
    &.contact {
      width: calc(30% - 20px);
      margin-top: 10px;
      margin-right: 20px;
      @media screen and (max-width: $lg-screen) {
        width: 100%;
        margin-bottom: 5px;
        margin-top: 10px;
      }
      &.border {
        border-right: 1px solid #979797;
        @media screen and (max-width: $lg-screen) {
          border-right: 0px solid #979797;
        }
      }
    }
  }
}

.contact-list {
  margin-top: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  .col-2 {
    margin-bottom: 5px;
  }
  .contact-card {
    position: relative;
    word-break: break-word;
    padding: 20px;
    margin: 0px 0px 20px 0px;
    &.brand {
      display: flex;
      justify-content: space-between;
    }
    .label {
      @include transition;
      display: block;
      max-width: 130px;
      padding: 5px 12px;
      border: 1px solid $primary;
      color: $primary;
      text-align: center;
      border-radius: 15px;
      &:not(:first-child) {
        margin-top: 10px;
      }
      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }
  .star {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .email {
    font-size: 13px;
    margin: 10px 0px;
  }
}

.export {
  justify-content: space-between;
}

.list-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.list-item {
  padding: 4px 10px;
  border-radius: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: $primary;
  border: 1px solid $primary;
  background-color: $white;
  margin-top: 7px;
  margin-right: 7px;
  &.btn {
    @include btn;
    cursor: pointer;
  }
  &.selected {
    @include btn;
    color: $white;
    background-color: $primary;
    .delete {
      @include btn;
      display: flex;
      align-items: center;
      margin-left: 8px;
      img {
        width: 15px;
        height: 12px;
      }
    }
  }
}

.container-center {
  width: calc(100% -20px);
  min-height: 310px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filters {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $md-screen) {
    margin-top: 20px;
  }
  @media screen and (max-width: $md-screen) {
    display: none;
  }
  .filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    span {
      opacity: 0.7;
      cursor: pointer;
      &:hover {
        color: $primary;
        opacity: 1;
      }
    }
    img {
      margin-left: 8px;
      margin-right: 10px;
      width: 8px;
    }
  }
  .masked-div {
    @media screen and (max-width: 1100px) {
      display: none;
    }
  }
}

.btn-edit {
  @include btn-edit;
}


.btn-save {
  @include btn;
  padding: 0 30px;
  font-size: 14px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22.5px;
  min-width: 150px;
  transition: all .3s ease-in-out;
  color: #FFFFFF;
  background-color: $success;
  &:hover {
    box-shadow: 0px 2px 13px -2px  rgba(156, 167, 177, 0.90);
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.btn-warning {
  @include btn;
  padding: 0 30px;
  font-size: 14px;
  height: 35px;
  border-radius: 22.5px;
  min-width: 150px;
  transition: all .3s ease-in-out;
  color: #FFFFFF;
  background-color: $error;
  &:hover {
    box-shadow: 0px 2px 13px -2px  rgba(156, 167, 177, 0.90);
  }
  &.disabled {
    opacity: 0.5;
  }
}

.btn-secondary {
  @include btn;
  padding: 0 30px;
  font-size: 14px;
  height: 35px;
  border-radius: 22.5px;
  min-width: 150px;
  transition: all .3s ease-in-out;
  color: #FFFFFF;
  background-color: $orange;
  &.not-active-status {
    background-color: $white;
    color: $orange;
    border: 1px solid $orange;
  }
  &:hover {
    box-shadow: 0px 2px 13px -2px  rgba(156, 167, 177, 0.90);
  }
  &.disabled {
    opacity: 0.5;
  }
}

.edit-container {
  display: flex;
  flex-direction: column;
  .edit-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
}

.notes {
  textarea {
    width: 95%;
    padding: 10px;
    min-height: 100px;
    font-size: 14px;
    font-family: "Montserrat-Regular", sans-serif;
    @media screen and (max-width: $xs-screen) {
      width: calc(92%);
      min-height: 130px;
      margin: 0px 0px;
      padding: 3%;
    }
  }
  p {
    white-space: pre-wrap;
  }
}

.create-notif {
  width: 50%;
  border-radius: 6px;
  box-shadow: 0px 2px 13px -2px  rgba(156, 167, 177, 0.30);
  color: $dark;
  margin: 15px 0px;
  padding: 20px;
  background-color: $white;
  @media screen and (max-width: $sm-screen) {
    width: calc(100% - 40px);
  }
  input {
    width:calc(100% - 20px);
  }
  textarea {
    width:calc(100% - 20px);
    padding: 10px;
    margin: 20px 0px;
    min-height: 100px;
    font-size: 14px;
    border: solid 1px #B2B2B2;
    font-family: "Montserrat-Regular", sans-serif;
    @media screen and (max-width: $xs-screen) {
      min-height: 200px;
    }
  }
}