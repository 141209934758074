@import './variables.scss';

.btn-container {
  display: flex;
  justify-content: space-between;
  &.export {
    justify-content: flex-end;
  }
  .export {
    @media screen and (max-width: $sm-screen) {
      display: none;
    }
  }
  .edit-shoowroom {
    transform: translate(0px, -60px );
    padding: 8px;
    @media screen and (max-width: $sm-screen) {
      transform: translate(0px, 0px );
      padding: 5px;
    }
  }
};


.showroom-info {
  min-height: 268px;
  background-color: $primary;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  color: $white;
  @media screen and (max-width: $sm-screen) {
    flex-direction: column;
  }
  .col-shoowroom {
    width: 60%;
    @media screen and (max-width: $sm-screen) {
      width: 100%;
    }
    &.border {
      width: 40%;
      border-left: 2px solid #0040B7;
      @media screen and (max-width: $sm-screen) {
        width: 100%;
        border-left: none;
        border-top: 2px solid #0040B7;
      }
      ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        @media screen and (max-width: $sm-screen) {
          margin: 20px 0px;
        }
        li {
          display: flex;
          align-items: center;
          margin-left: 20px;
          margin-top: 10px;
          margin-bottom: 10px;
          @media screen and (max-width: $sm-screen) {
            margin-top: 12px;
            margin-bottom: 12px;
          }
          .icon-white {
            min-width: 35px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          p {
            margin-left: 10px;
            @media screen and (max-width: $sm-screen) {
              max-width: 200px;
            }
          }
        }
      }
    }
    .col-container {
      width: 100%;
      height: 100%;
      min-height: 260px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 50%;
      }
      .info-label {
        margin-top: 20px;
        font-size: 23px;
      }
      .nb-xl {
        font-size: 80px;
        .unit {
          font-size: 50px;
        }
      }
    }
  }
};

.search-container {
  .select-products {
    margin-top: 4px;
    min-width: calc(33% - 20px);
  }
}