@import './variables.scss';

.scan-result-container {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $sm-screen) {
    height: 100vh;
    position: fixed;
    width: 100%;
    top: 0;
    left:0;
  }
  .scan-result {
    background-color: $primary;
    color: $white;
    width: 50%;
    height: 80%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    @media screen and (max-width: $sm-screen) {
      width: 100%;
      height: 100%;
      border-radius: 0px;
    }
    button {
      padding: 10px 20px;
    }
    h1,h2 {
      margin: 10px;
    }
  }
}

.qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .qr {
    margin-top: 50px;
    width: 50%;
    @media screen and (max-width: $sm-screen) {
      width: 100%;
    }
  }
}