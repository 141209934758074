@import '../../../../../styles/variables';

.container {
  @include row;
  justify-content: space-between;
  padding: 30px;
  @media screen and (max-width: $sm-screen) {
    padding: 20px;
  }
  .block {
    @include block;
    margin-bottom: 30px;
    h2 {
      margin-bottom: 10px;
    }
    .row {
      @include row;
      justify-content: space-between;
    }
  }
}