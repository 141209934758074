@import '../../../../styles/variables';

.container {
  @include page-container;
  .header {
    @include page-header;
    width: calc(100% - 60px);
    max-width: calc(100% - 60px);
    margin: 0px;
    background-color: $white;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $md-screen) {
      width: calc(100% - 40px);
      max-width: calc(100% - 40px);
      flex-direction: column;
      align-items: flex-start;
    }
    .actions {
      width: 300px;
      min-width: 300px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: $md-screen) {
        margin-top: 10px;
        width: 100%;
        min-width: 100%;
        display: flex;
        justify-content: space-between;
      }
      @media screen and (max-width: $sm-screen) {
        flex-direction: column-reverse;
        align-items: flex-start;
      }
      .steps {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .link {
          @include btn;
          @include bold;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0px;
          margin-right: 20px;
          opacity: 0.3;
          border-bottom: 2px solid transparent;
          &:hover {
            opacity: 1;
          }
          &.active {
            border-bottom: 2px solid $orange;
            opacity: 1;
          }
          span {
            margin-left: 4px;
          }
        }
      }
      .submit {
        @include btn-edit;
        background-color: $primary;
        padding: 8px 20px;
        display: flex;
        justify-content: center;
        @media screen and (max-width: $sm-screen) {
          position: fixed;
          bottom: 20px;
          right: 20px;
        }
      }
    }
  }
  .content {  
    overflow-x: hidden;
    .steps-container {
      @include transition;
      position: absolute;
      display: flex;
      @media screen and (max-width: $sm-screen) {
        top: 80px;
      }
      .step-content {
        padding: 30px;
        @media screen and (max-width: $sm-screen) {
          padding: 20px;
        }
      }
    }
  }
}