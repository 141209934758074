@import '../../../styles/variables';

.container {
  @include transition;
  position: fixed;
  z-index: 0;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: none;
  &.active {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 500;
    background-color: rgba($dark, 0.4);
  }
}