@import './variables.scss';
@import './mixin.scss';

.file-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  margin: 10px 0px;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  background-color: $white;
  box-shadow: $shadow;
  min-height: 60px;
}

h3 {
  margin-top: 30px;
  font-size: 14px;
}

.save-container {
  display: flex;
  justify-content: flex-end;
}

.comments {
  textarea {
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    margin: 10px 0px;
    min-height: 120px;
    font-size: 14px;
    font-family: "Montserrat-Regular", sans-serif;
    padding: 10px;
  }
  button {
    float: right;
    padding: 4px 15px;
    transform: translate(-20px, -27px )
  }
}

.btn-download {
  @include btn;
  font-size: 13px;
  width: 38px;
  height: 38px;
  padding: 4px;
  color: $white;
  border-radius: 20px;
  background-color: $orange;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 16px;
  }
  &:hover {
    box-shadow: 0px 2px 13px -2px  rgba(156, 167, 177, 0.90);
  }
}

.input {
  margin-bottom: 10px;
}

.validation-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  @media screen and (max-width: 1350px) {
    flex-direction: column;
    align-items: center;
    button {
      padding: 0 5px;
      margin: 10px 0px;
      max-width: 200px;
    }
  }
}

.edit-animation {
  .card {
    padding: 20px;
  }

  .files-list {
    margin: 20px 0px;
  }

  .submit {
    float: right;
  }

  textarea {
    margin: 20px 0px;
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    min-height: 150px;
    padding: 10px;
    font-family: "Montserrat-Regular", sans-serif;
    &.invalid {
      border:1px solid red;
    }
  }
  
}

label {
  cursor: pointer;
  /* Style as you please, it will become the visible UI component. */
}

.hide-file-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}


.input-dimension {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  input {
    margin: 10px 5px;
    border: 1px solid rgba($color: #0A1760, $alpha: 0.28);
    height: 40px;
    border-radius: 13px;
    padding: 0px 15px;
    max-width: 80px;
    &:invalid {
      border:1px solid red;
    }
  }
  span{
    padding: 0 5px;
  }
}

.btn-upload {
  margin-top:20px;
  display: inline-block;
}

.files-actions {
  display: flex;
  button {
    margin-left: 10px;
  }
}
