@import './variables.scss';
@import './mixin.scss';

.switch-field{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  p{
    font-size: $regular;
    font-family: "Montserrat-SemiBold";
    .sub{
      font-family: "Montserrat-Regular"
    }
  }

  .switch{
    padding-right: 20px;
  }

}

.list-options{

  .container-switch {
    margin-top: $margin-sm;
    margin-bottom: 10px;
    // border-bottom: solid 1px $md-grey;
    .switch-field {
      margin-bottom: 30px;
    }
  }

  .options-water{
    margin-top: 10px;
    display: flex;
    align-items: center;
    a{
      margin-left: $margin-sm;
      color: $text-disabled-color;
    }
  }

  .standiste{

    .section-title{
      font-size: 16px;
      padding-left: 0;
      padding-right: 0;
      margin: $margin-sm 0px;
    }

    .container-inputs{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .container-input{
        width: 100%;
      }
      @media screen and (min-width: #{$sm-screen}) {
        flex-direction: row;
        .container-input{
          width: calc(33% - 5px);
        }
      }
    }
  }
}

.select-items{
  ul{
    padding: 0;
    margin: 0;

    &.loading li{
      padding: 0;
      font-size: 0;
      margin: 0;
    }

    &:not(.loading) li{
      padding: $margin-xs;
      border: solid 1px $md-grey;
      margin-bottom: 10px;

      &:not(:last-child){
        margin-right: $margin-xs;
      }

      &.selected{
        border-color: $primary;
        background-color: $primary;
        color:$white;
      }

    }

    li{
      display: inline-block;
      font-size: $regular;
      color: $text-color;
      border-radius: $radius;
      cursor: pointer;
      outline: none;
      @include remove-highlight();
    }

  }
}

