@import '../../../styles/variables';

.container {
  width: 400px;
  pointer-events: all;
  position: relative;
  background-color: $white;
  border-radius: $radius;
  padding: 20px;
  z-index: 502;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .close {
    @include btn;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 5px;
    img {
      width: 18px;
    }
  }
  h2 {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .input {
    display: none;
  }
  .btn {
    display: block;
    @include btn-edit;
    padding: 10px 20px;
    &.success {
      margin-top: 20px;
    }
  }
}