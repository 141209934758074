@import '../../../styles/variables';

.container {
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: $white;
  justify-content: center;
  .col {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $sm-screen) {
      width: 100%;
    }
    &.img {
      position: relative;
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      background-color: $primary;
      @media screen and (max-width: $sm-screen) {
        display: none;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        &.bottom {
          position: absolute;
          bottom: 0px;
        }
      }      
    }
    .content {
      max-width: 410px;
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 160px;
        margin-bottom: 20px;
      }
  
      h2{
        margin-top: 0;
        text-align: center;
        margin-bottom: $margin-md;
        color: $primary;
        font-family: "Montserrat-SemiBold";
        font-size: 20px;
        margin-bottom: 20px;
      }

      .input {
        width: 100%;
        height: 40px;
        border-radius: 14px;
        margin-top: 20px;
        padding: 0px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgba($color: #0A1760, $alpha: 0.28);
        img {
          max-width: 16px;
          margin-top: 16px;
          cursor: pointer;
          &.email {
            max-width: 18px;
            margin-top: 17px;
          }
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
        }
        input {
          font-size:15px;
          height: 80%;
          width: 80%;
          border: 0px;
          color: $primary;
          &::placeholder {
            color: rgba($color: #0A1760, $alpha: 0.43)
          }
        }
      }
      .message {
        font-size: 14px;
        min-height: 40px;
        margin: 10px;
        display: flex;
        text-align: center;
        align-items: center;
      }
      .connection {
        @include btn;
        cursor: pointer;
        margin-top: 20px;
        background-color: $orange;
        color: $white;
        min-width: 200px;
        min-height: 35px;
        font-size: 16px;
        padding: 5px 15px;
        border-radius: 24px;
        &.disable {
          cursor: not-allowed;
          opacity: 0.4;
        }
      }
      .forgot {
        @include btn;
        cursor: pointer;
        color: $dark;
        font-size: 13px;
        border-bottom: 1px solid $primary;
      }
    }
  }
}