@import '../../../styles/variables';

.container {
  margin: 20px;
  padding: 15px 0px;
  border-bottom: 1px solid rgba($primary, 0.2);
  border-top: 1px solid rgba($primary, 0.2);
  font-size: 14px;
  color: $primary;
  .selected {
    display: flex;
    justify-content: space-between;
    p {
      width: 170px;
    }
    .toggle {
      @include btn;
      transform: rotate(180deg);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 14px;
      }
    }
  }
  .select {
    display: none;
    button {
      width: 100%;
      margin-top: 10px;
      @include btn;
      text-align: left;
      color: rgba($dark, 0.5);
      &:hover {
        color: rgba($dark, 0.8);
      }
    }
  }
  &.active {
    .select {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
    }
    .toggle {
      transform: rotate(0deg);
    }
  }
}